<template lang="pug">
  .distribution-guide-page
    .pos-fixed
      top-bar
      first-head
    .pt-top-head-height
      .content(v-html="guideContent")
    bottomFoot
</template>
<script>
import {getInformation} from '@/api/index'
export default {
  data() {
    return { 
      guideContent:""
    }
  },
  mounted(){
    getInformation('4',{
      params:{
        flag:'row'
      }
    }).then(res => {
      if(res.code == 0) {
        this.guideContent = res.data.content
      }
    })
  }   
}
</script>

<style lang="less">
.distribution-guide-page{
  .content{
    width: 1120px;
    margin: 30px auto;
    font-size: 16px;
    color: #333;
  }
}
</style>
